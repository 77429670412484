import { Routes, Route, Link } from 'react-router-dom';
import ScreenHeader from 'src/components/layout/ScreenHeader';
import { ResourcePlanner } from 'src/screens/ResourcePlanner';
import { designSevenPagePath } from 'src/designSevenPagePath';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageRoute from '../PageRoute';

export const ProjectsRouter = () => {
  const { t } = useTranslation('screenHeaderTitle');
  return (
    <Routes>
      <Route
        path={designSevenPagePath.projects.resourcePlanner}
        element={
          <>
            <ScreenHeader
              actions={
                <Button
                  variant="outlined"
                  color="secondary"
                  component={Link}
                  size="small"
                  to="/reports/revenue-forecast"
                >
                  {t('RevenueForecast')}
                </Button>
              }
            />
            <PageRoute
              pageTitle="ResourcePlanner"
              pageId="c210b1c5-f3df-44d8-b17c-43c10a504c0d"
              pageIdentifier="NewResourcePlanner"
            >
              <ResourcePlanner />
            </PageRoute>
          </>
        }
      />
    </Routes>
  );
};
