export interface IFocusStateProps {
  isFocusStateEnabled: boolean;
}

export type TFocusStateActionProps = {
  type: 'TOGGLE_FOCUS_STATE';
};

export const focusStateReducer = (
  state: IFocusStateProps,
  action: TFocusStateActionProps,
): IFocusStateProps => {
  switch (action.type) {
    case 'TOGGLE_FOCUS_STATE':
      localStorage.setItem('isFocusStateEnabled', (!state.isFocusStateEnabled).toString());
      return {
        ...state,
        isFocusStateEnabled: !state.isFocusStateEnabled,
      };

    default:
      return state;
  }
};

export const initFocusStateStore: IFocusStateProps = {
  isFocusStateEnabled: localStorage.getItem('isFocusStateEnabled') === 'true',
};
