import { useTranslation } from 'react-i18next';
import { useGetBackToTimeLogPage } from 'src/stores/PageStore';

import { Heading } from 'src/components/ui-components/Heading';
import { TextLink } from 'src/components/ui-components/Link';
import FeatureControl from 'src/components/utils/FeatureControl';
import { IconButton } from 'src/components/mui-components';
import { CenterFocusStrong } from '@mui/icons-material';
import { useFocusStateDispatch, useIsFocusStateEnabled } from 'src/stores/FocusStateStore';
import { useCallback } from 'react';
import { Badge } from '@mui/material';
import { Navigation } from './components/Navigation';
import { MainSearch } from './components/MainSearch';
import TimeTrackerButton from './components/TimeTrackerButton';
import HelpButton from './components/HelpButton';
import { HeaderBase } from './HeaderBase';

import styles from './Header.module.scss';
import SignUpButton from './components/SignUpButton';

export const HeaderWithNavigation = () => {
  const isFocusStateEnabled = useIsFocusStateEnabled();
  const focusStateDispatch = useFocusStateDispatch();

  const handleFocusButtonClick = useCallback(() => {
    if (focusStateDispatch) {
      focusStateDispatch({
        type: 'TOGGLE_FOCUS_STATE',
      });
    }
  }, [focusStateDispatch]);

  return (
    <HeaderBase
      primaryArea={<Navigation />}
      optionsArea={
        <>
          <SignUpButton />
          <IconButton
            aria-pressed={isFocusStateEnabled}
            title={isFocusStateEnabled ? 'Turn focus state off' : 'Turn focus state on'}
            onClick={handleFocusButtonClick}
            sx={{ '&[aria-pressed="true"]': { backgroundColor: '#efefef' } }}
          >
            <Badge color="primary" variant="dot" invisible={!isFocusStateEnabled}>
              <CenterFocusStrong />
            </Badge>
          </IconButton>
          <FeatureControl
            indexControlName="TimeTracker"
            userFeatureKey="useStopwatchTimeTracking"
            pageIdentifierKey="TimeTrackingBase"
          >
            <TimeTrackerButton />
          </FeatureControl>
          <FeatureControl indexControlName="Search">
            <MainSearch />
          </FeatureControl>
          <FeatureControl indexControlName="Users">
            <HelpButton data-automation-id="HeaderHelpSection" />
          </FeatureControl>
        </>
      }
      data-automation-id="HeaderWithNavigation"
    />
  );
};

interface HeaderWithHeadingProps {
  heading: string;
  isHelpVisible?: boolean;
}

export const HeaderWithHeading = ({ heading, isHelpVisible = true }: HeaderWithHeadingProps) => {
  const { t } = useTranslation('header');

  const backToTimeLogUrl = useGetBackToTimeLogPage();
  return (
    <HeaderBase
      primaryArea={
        <div className={styles.Heading}>
          <Heading level={1} as={2} medium data-automation-id="HeaderWithHeading">
            {heading}
          </Heading>
        </div>
      }
      optionsArea={
        <>
          <TextLink
            href={backToTimeLogUrl}
            className={styles.BackLink}
            data-automation-id="HeaderGoBack"
            useTextColor
          >
            {t('SettingsPagesBackToTimeLogLink')}
          </TextLink>
          {isHelpVisible ? (
            <FeatureControl indexControlName="Users">
              <HelpButton data-automation-id="HeaderHelpSection" />
            </FeatureControl>
          ) : null}
        </>
      }
    />
  );
};
