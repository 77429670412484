import { useTranslation } from 'react-i18next';
import { Stack, Typography } from 'src/components/mui-components';
// import { useGetRevenueForecastMigrationStatus } from 'src/apis/revenueForecastAPI';
import { SetupWaitScreen } from 'src/components/ui-components';

export const InitialSetup = () => {
  const { t } = useTranslation('revenueForecast');
  // const {
  //   canTriggerMigration,
  //   isResourceMigrated,
  //   isProjectMigrated,
  //   isResourcePlanMigrated,
  //   canAddResourcePlan,
  // } = useGetRevenueForecastMigrationStatus();

  return (
    <SetupWaitScreen>
      <Stack>
        <Typography component="h2" variant="h1" fontWeight={700}>
          {t('MigrationStatusHeader')}
        </Typography>
        <Typography>{t('MigrationStatusText')}</Typography>
      </Stack>
    </SetupWaitScreen>
  );
};
