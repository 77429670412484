import { ReactNode } from 'react';
import styles from './SetupWaitScreen.module.scss';

export const SetupWaitScreen = ({
  topArea,
  children,
}: {
  children: ReactNode;
  topArea?: ReactNode;
}) => (
  <div className={styles.wrapper}>
    {topArea && topArea}

    <div className={styles.intro}>
      <div className={styles.introContent}>{children}</div>
    </div>
  </div>
);
