import { addMonths, format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import {
  useGetRevenueForecastMigrationStatus,
  useGetRevenueForecastViewOptions,
  usePostCompleteMigration,
} from 'src/apis/revenueForecastAPI';
import { FilterLayout } from 'src/components/layout/FilterLayout';
import {
  IViewOptionsField,
  ViewOptionsChangeParameters,
} from 'src/components/layout/FilterLayout/types';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { safeParseJson } from 'src/utils/object';

import { MonthPickerDates } from 'src/components/ui-components/MonthPicker';
import { IFilterProps, TFilterID } from 'src/reducers/FilterReducer/FilterReducer';
import { useGetRPSignalRConfig } from 'src/apis/resourcePlannerAPI';
import { useQueryClient } from '@tanstack/react-query';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { REVENUE_FORECAST_MIGRATION_STATUS_KEY } from 'src/apis/revenueForecastAPI/get/migrationStatus/migrationStatus';
import logger from 'src/utils/logger';
import { PeriodPicker, Table } from './components';
import {
  revenueForecastSelectedFilterListStateKey,
  revenueForecastViewOptionsStateKey,
} from './localStorageKeys';
import { InitialSetup } from './components/InitialSetup';

// TODO: persist mui table state (density, column width, column visibility, sort)
// TODO: styling of adjusting column width (Baiba on it)

export const RevenueForecast = () => {
  const { fields: initialFields } = useGetRevenueForecastViewOptions();

  const pageIdentifier = useGetCurrentPageIdentifier();
  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);

  const localViewOptions = localStorage.getItem(revenueForecastViewOptionsStateKey);
  const localViewOptionsParsed = safeParseJson(localViewOptions || '{}');
  if (localViewOptionsParsed && !localViewOptionsParsed['period-starts-at']) {
    localViewOptionsParsed['period-starts-at'] = format(addMonths(new Date(), -3), 'yyyy-MM-dd');
  }
  if (localViewOptionsParsed && !localViewOptionsParsed['period-ends-at']) {
    localViewOptionsParsed['period-ends-at'] = format(addMonths(new Date(), 3), 'yyyy-MM-dd');
  }

  const [changedViewOptions, setChangedViewOptions] =
    useState<ViewOptionsChangeParameters>(localViewOptionsParsed);

  const [monthRange, setMonthRange] = useState<MonthPickerDates>({
    from: changedViewOptions['period-starts-at']
      ? new Date(changedViewOptions['period-starts-at'])
      : addMonths(new Date(), -3),
    to: changedViewOptions['period-ends-at']
      ? new Date(changedViewOptions['period-ends-at'])
      : addMonths(new Date(), 3),
  });

  const fields: Array<IViewOptionsField> = useMemo(
    () =>
      initialFields.map((field: IViewOptionsField) => {
        const value = (() => {
          if (changedViewOptions && changedViewOptions[field.name]) {
            return changedViewOptions[field.name];
          }
          return field.value;
        })();

        return {
          ...field,
          value,
          options: field.options.map((option) => ({
            ...option,
          })),
        };
      }),
    [initialFields, changedViewOptions],
  );

  const {
    isMigrated,
    isDataSynchronizationEnabled,
    canTriggerMigration,
    isError: isMigrationStatusError,
    isLoading: isMigrationStatusLoading,
  } = useGetRevenueForecastMigrationStatus();

  const { mutate: completeTestMigration } = usePostCompleteMigration();
  useEffect(() => {
    if (canTriggerMigration) {
      completeTestMigration();
    }
  }, [canTriggerMigration, completeTestMigration]);

  const { tenantId, serviceUrl } = useGetRPSignalRConfig();
  const qc = useQueryClient();

  useEffect(() => {
    (async () => {
      if (serviceUrl) {
        try {
          const hubConnection = new HubConnectionBuilder()
            .withUrl(serviceUrl)
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

          // Starts the SignalR connection
          await hubConnection.start().catch((error) => {
            logger.warn(`SignalR Connection Error, ${error}`);
          });

          // Once started, invokes the sendConnectionId in our ChatHub inside our ASP.NET Core application.
          if (hubConnection.connectionId) {
            hubConnection.invoke('SubscribeToTenantId', tenantId);
          }

          hubConnection.on('ReloadMigrationStatus', () => {
            qc.invalidateQueries([REVENUE_FORECAST_MIGRATION_STATUS_KEY]);
          });

          hubConnection.on('ReloadEmployeeCount', () => {
            qc.invalidateQueries([REVENUE_FORECAST_MIGRATION_STATUS_KEY]);
          });

          hubConnection.on('ReloadProjectCount', () => {
            qc.invalidateQueries([REVENUE_FORECAST_MIGRATION_STATUS_KEY]);
          });
        } catch (ex) {
          // ignore singalr connection error
          // eslint-disable-next-line no-console
          console.error(ex);
        }
      }
    })();
  }, [serviceUrl, tenantId, qc]);

  useEffect(() => {
    localStorage.setItem(revenueForecastViewOptionsStateKey, JSON.stringify(changedViewOptions));
  }, [changedViewOptions]);

  const onViewOptionsChange = (optionItems: ViewOptionsChangeParameters[]) => {
    const options = optionItems.map((item) => ({ [item.name]: item.value }));
    const optionsToObject = Object.assign({}, ...options);

    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  const getSelectedFilterFromLocalStorage = (): Record<TFilterID, IFilterProps> => {
    const filterFromLocalStorage: Record<TFilterID, IFilterProps> =
      safeParseJson(localStorage.getItem(revenueForecastSelectedFilterListStateKey) || '') || {};
    if (filterList.length > 0) {
      const flatMapped = filterList.flatMap((x) => x.filterItems).map((x) => x?.name);
      Object.keys(filterFromLocalStorage).forEach((key) => {
        if (flatMapped.indexOf(key) === -1) {
          delete filterFromLocalStorage[key];
        }
      });
    }
    return filterFromLocalStorage;
  };

  return (
    <ResponseHandler
      isLoading={isLoading || isMigrationStatusLoading}
      isEmpty={filterList.length <= 0}
      isError={isError || isMigrationStatusError}
    >
      {isMigrated && isDataSynchronizationEnabled ? (
        <FilterLayout
          filterList={filterList}
          selectedFilterList={getSelectedFilterFromLocalStorage()}
          viewOptionsPeriodPicker={
            <PeriodPicker
              initialMonthRange={monthRange}
              setMonthRange={setMonthRange}
              onChange={onViewOptionsChange}
            />
          }
          viewOptionsFields={fields}
          viewOptionsChange={onViewOptionsChange}
          localStorageNamePrefix="revenue-forecast"
        >
          <Table selectedViewOptions={changedViewOptions} />
        </FilterLayout>
      ) : (
        <InitialSetup />
      )}
    </ResponseHandler>
  );
};

export default RevenueForecast;
