export const filterFullResultRevenueForecast = {
  Entities: [
    {
      Properties: {
        FilterCategory: 'Project',
        FilterItems: [
          {
            Id: 3,
            Name: 'Project',
            ContentUrl: 'api/v2/filters/project/content',
            ChildFilters: ['AccountManager', 'ApprovalManager'],
            ParentFilters: ['ProjectManager', 'ContractType'],
            isInclude: true,
          },
          {
            Id: 9,
            Name: 'ProjectCategory',
            ContentUrl: 'api/v2/filters/project-category/content',
            ChildFilters: ['Task', 'TaskType'],
            ParentFilters: ['ContractType', 'Project'],
            isInclude: true,
          },
          {
            Id: 48,
            Name: 'DepartmentProject',
            ContentUrl: 'api/v2/filters/department/content',
            ChildFilters: ['ProjectCategory', 'Project'],
            ParentFilters: ['DepartmentEmployee', 'EmployeeType'],
            isInclude: true,
          },
        ],
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/filters/list/DesignSevenExampleReport',
      Rel: 'self',
    },
  ],
};
