import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFetch } from 'src/utils/fetcher';
import { formatUrl } from 'src/utils/url';
import { REVENUE_FORECAST_MIGRATION_STATUS_KEY } from '../../get/migrationStatus/migrationStatus';

const POST_START_FORECAST_ONBOARDING = '/api/v2/budget-forecasting/start-forecast-onboarding';
export const POST_COMPLETE_MIGRATION_MSW_STATUS_KEY = 'PostBFCompleteMigration';

export default () => {
  const path = formatUrl(POST_START_FORECAST_ONBOARDING);
  const completeTestMigration = () =>
    postFetch({
      path,
      key: POST_COMPLETE_MIGRATION_MSW_STATUS_KEY,
    });
  const qc = useQueryClient();

  return useMutation(completeTestMigration, {
    onSuccess: () => {
      qc.invalidateQueries([REVENUE_FORECAST_MIGRATION_STATUS_KEY]);
    },
  });
};
