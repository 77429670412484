import classNames from 'classnames';
import { Stack } from 'src/components/mui-components';
import { ReactNode } from 'react';
import { useIsFocusStateEnabled } from 'src/stores/FocusStateStore';
import HeaderTitle from './components/HeaderTitle';
import MenuButton from './components/MenuButton';
import BookmarkButton from './components/BookmarkButton';
import styles from './ScreenHeader.module.scss';

interface ScreenHeaderProps {
  urlForTest?: string;
  actions?: ReactNode;
}

export default ({ urlForTest, actions }: ScreenHeaderProps) => {
  const isFocusStateEnabled = useIsFocusStateEnabled();

  if (isFocusStateEnabled) {
    return null;
  }

  return (
    <div data-automation-id="ScreenHeader" className={classNames(styles.ScreenHeaderContainer)}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <HeaderTitle />
          <Stack direction="row" alignItems="center" gap={0}>
            <BookmarkButton />
            <MenuButton urlForTest={urlForTest} />
          </Stack>
        </Stack>
        {actions && actions}
      </Stack>
    </div>
  );
};
