export const getRevenueForecastMigrationStatusComplete = {
  Model: {
    class: 'migrationstatusresponse',
    properties: {
      tenantId: '20923',
      isDataSynchronizationEnabled: true,
      isMigrated: true,
      canTriggerMigration: true,
      canAddResourcePlan: true,
    },
    links: [{ href: '/migration/getmigrationstatus', rel: 'self' }],
  },
  DeterministicHash: 1803085836,
  StatusCode: 200,
};
