import { GlobalHotKeys, configure } from 'react-hotkeys';
import { useFocusStateDispatch } from 'src/stores/FocusStateStore';
import { useMainSearchDispatch } from 'src/stores/MainSearchStore';

// this configuration is needed to allow the shortcut system to work with input fields
configure({
  /**
   * The HTML tags that React HotKeys should ignore key events from. This only works
   * if you are using the default ignoreEventsCondition function.
   * @type {String[]}
   */
  ignoreTags: ['input', 'select', 'textarea'],

  /**
   * The function used to determine whether a key event should be ignored by React
   * Hotkeys. By default, keyboard events originating elements with a tag name in
   * ignoreTags, or a isContentEditable property of true, are ignored.
   *
   * @type {Function<KeyboardEvent>}
   */
  ignoreEventsCondition: () => false,
});

export const shortcutElementNames = {
  mainSearch: 'MainSearch',
};

export const ShortcutSystem = () => {
  const focusStateDispatch = useFocusStateDispatch();
  const mainSearchDispatch = useMainSearchDispatch();
  const mainSearchInput = document.getElementsByName(shortcutElementNames.mainSearch);

  const keyMap = {
    TOGGLE_SEARCH: ['F2'],
    TOGGLE_FOCUS_STATE: ['ctrl+F1'],
  };

  const toggleSearch = () => {
    if (mainSearchDispatch) {
      mainSearchDispatch({
        type: 'TOGGLE_SEARCH',
      });

      // main search input
      if (mainSearchInput.length > 0) {
        // Add an arbitrary timeout to ensure the element is rendered
        setTimeout(() => {
          mainSearchInput[0].focus();
        }, 100);
      }
    }
  };

  const toggleFocusState = () => {
    if (focusStateDispatch) {
      focusStateDispatch({
        type: 'TOGGLE_FOCUS_STATE',
      });
    }
  };

  const handlers = { TOGGLE_SEARCH: toggleSearch, TOGGLE_FOCUS_STATE: toggleFocusState };

  return <GlobalHotKeys keyMap={keyMap} handlers={handlers} />;
};
