import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IRevenueForecastModelResult } from '../../types';

const REVENUE_FORECAST_VIEW_OPTIONS_URL_PATH = '/api/v2/budget-forecasting/view-options';
export const REVENUE_FORECAST_VIEW_OPTIONS_MSW_STATUS_KEY = 'GetRevenueForecastViewOptionsAPI';

export const REVENUE_FORECAST_VIEW_OPTIONS_KEY = 'REVENUE_FORECAST_VIEW_OPTIONS';

const revenueForecastViewOptionsFetch = (): Promise<IRevenueForecastModelResult> =>
  getFetch({
    path: REVENUE_FORECAST_VIEW_OPTIONS_URL_PATH,
    key: REVENUE_FORECAST_VIEW_OPTIONS_MSW_STATUS_KEY,
  });

export const useGetRevenueForecastViewOptions = () => {
  const { data: { model: { entities = [] } = {} } = {}, ...restProps } = useQuery(
    [REVENUE_FORECAST_VIEW_OPTIONS_KEY],
    revenueForecastViewOptionsFetch,
  );

  return {
    fields: entities.map(({ properties: { name = '', options = [] } = {} }) => ({
      name,
      value: options.find(({ selected }) => selected)?.value,
      options: options.map(({ value }) => ({
        value,
      })),
    })),
    ...restProps,
  };
};
