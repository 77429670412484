import { createContext, useContext, useReducer } from 'react';
import {
  focusStateReducer,
  IFocusStateProps,
  initFocusStateStore,
  TFocusStateActionProps,
} from 'src/reducers/focusStateReducer';

interface IFocusStateStoreProps {
  children: JSX.Element | JSX.Element[];
}

const FocusStateStoreContext = createContext<IFocusStateProps | undefined>(undefined);
const FocusStateDispatchContext = createContext<React.Dispatch<TFocusStateActionProps> | undefined>(
  undefined,
);
export const FocusStateProviderForTest = FocusStateStoreContext.Provider;

export const useFocusStateDispatch = (): React.Dispatch<TFocusStateActionProps> | undefined =>
  useContext(FocusStateDispatchContext);

export const useFocusStateStore = (): IFocusStateProps | undefined =>
  useContext(FocusStateStoreContext);

export const useIsFocusStateEnabled = () =>
  useContext(FocusStateStoreContext)?.isFocusStateEnabled || false;

export const FocusStateStore = ({ children }: IFocusStateStoreProps) => {
  const [focusStateState, focusStateDispatch] = useReducer(focusStateReducer, initFocusStateStore);

  return (
    <FocusStateStoreContext.Provider value={focusStateState}>
      <FocusStateDispatchContext.Provider value={focusStateDispatch}>
        {children}
      </FocusStateDispatchContext.Provider>
    </FocusStateStoreContext.Provider>
  );
};
